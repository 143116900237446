import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    function fitToViewport() {
      const navbars = document.getElementsByTagName("nav");

      if (!navbars.length) {
        return;
      }

      const navbar = navbars[0];
      const landingRow = document.getElementById("landing-row");

      landingRow.style.height = Math.max(505 - navbar.offsetHeight, window.innerHeight - navbar.offsetHeight) + "px";
    }

    window.onload = fitToViewport;
    window.onresize = fitToViewport;

    return () => {
      window.onload = null;
      window.onresize = null;
    }
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-tli position-relative">
        <div className="position-absolute z-n1 start-0 top-0 end-0 bottom-0 bg-transparent">
          <div className="row">
            <div className="col-6 h-100 position-absolute end-0 px-0 right-panel-col" />
          </div>
        </div>
        <div className="container-fluid">
          <span className="d-flex align-items-center">
            <img src="img/logo.svg" className="footer-logo" alt="Oshunwide Logo" />
            <a className="navbar-brand display-text text-body ms-2" href="#top">
              Oshunwide
            </a>
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link text-dark display-text navbar-text mx-2"
                  aria-current="page"
                  href="#features"
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark display-text navbar-text mx-2"
                  href="#aboutus"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link text-dark display-text navbar-text ms-5 px-4 border border-2 border-dark rounded-pill"
                  href="https://form.jotform.com/241206390301846"
                >
                  Become an Operator
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row full-height-row mb-5" id="landing-row">
          <div className="col-lg-6 left-panel-col d-flex flex-column">
            <div className="row flex-grow-1 align-items-center">
              <div className="col-12">
                <div className="row my-3">
                  <div className="col-12">
                    <span>
                      <svg
                        width="35px"
                        height="35px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <g transform="rotate(10) translate(2 -1029.4)">
                          <path
                            d="m7 1031.4c-1.5355 0-3.0784 0.5-4.25 1.7-2.3431 2.4-2.2788 6.1 0 8.5l9.25 9.8 9.25-9.8c2.279-2.4 2.343-6.1 0-8.5-2.343-2.3-6.157-2.3-8.5 0l-0.75 0.8-0.75-0.8c-1.172-1.2-2.7145-1.7-4.25-1.7z"
                            fill="#fc4300"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h1 className="display-text">Make it Personal!</h1>
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="col-12 text-secondary">
                    <span>
                      Order from your favorite stores! Then book your favorite
                      services!
                    </span>
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="col-12 text-secondary">
                    <span>Get them to your doorstep all in-app. </span>
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-6">
                    <a
                      href="https://ugfi7.app.link/9A9ZjWN2dJb"
                      target="_blank"
                      rel="noreferrer nofollow"
                      className="float-end text-decoration-none text-body d-inline-flex align-items-center py-2 px-4 border rounded-pill"
                    >
                      <svg
                        fill="#000000"
                        width="15px"
                        height="15px"
                        viewBox="-1 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m3.751.61 13.124 7.546-2.813 2.813zm-2.719-.61 12.047 12-12.046 12c-.613-.271-1.033-.874-1.033-1.575 0-.023 0-.046.001-.068v.003-20.719c-.001-.019-.001-.042-.001-.065 0-.701.42-1.304 1.022-1.571l.011-.004zm19.922 10.594c.414.307.679.795.679 1.344 0 .022 0 .043-.001.065v-.003c.004.043.007.094.007.145 0 .516-.25.974-.636 1.258l-.004.003-2.813 1.593-3.046-2.999 3.047-3.047zm-17.203 12.796 10.312-10.359 2.813 2.813z" />
                      </svg>
                      <span className="ms-2">Android</span>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://ugfi7.app.link/wu6NHKo2dJb"
                      target="_blank"
                      rel="noreferrer nofollow"
                      className="text-decoration-none text-body d-inline-flex align-items-center py-2 px-4 border rounded-pill"
                    >
                      <svg
                        width="15px"
                        height="15px"
                        viewBox="-1.5 0 20 20"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-102.000000, -7439.000000)"
                            fill="#000000"
                          >
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              <path
                                d="M57.5708873,7282.19296 C58.2999598,7281.34797 58.7914012,7280.17098 58.6569121,7279 C57.6062792,7279.04 56.3352055,7279.67099 55.5818643,7280.51498 C54.905374,7281.26397 54.3148354,7282.46095 54.4735932,7283.60894 C55.6455696,7283.69593 56.8418148,7283.03894 57.5708873,7282.19296 M60.1989864,7289.62485 C60.2283111,7292.65181 62.9696641,7293.65879 63,7293.67179 C62.9777537,7293.74279 62.562152,7295.10677 61.5560117,7296.51675 C60.6853718,7297.73474 59.7823735,7298.94772 58.3596204,7298.97372 C56.9621472,7298.99872 56.5121648,7298.17973 54.9134635,7298.17973 C53.3157735,7298.17973 52.8162425,7298.94772 51.4935978,7298.99872 C50.1203933,7299.04772 49.0738052,7297.68074 48.197098,7296.46676 C46.4032359,7293.98379 45.0330649,7289.44985 46.8734421,7286.3899 C47.7875635,7284.87092 49.4206455,7283.90793 51.1942837,7283.88393 C52.5422083,7283.85893 53.8153044,7284.75292 54.6394294,7284.75292 C55.4635543,7284.75292 57.0106846,7283.67793 58.6366882,7283.83593 C59.3172232,7283.86293 61.2283842,7284.09893 62.4549652,7285.8199 C62.355868,7285.8789 60.1747177,7287.09489 60.1989864,7289.62485"
                                id="apple-[#173]"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span className="ms-2">Apple</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-flex right-panel-col d-flex align-items-center justify-content-center">
            <img
              className="d-inline landing-row-img"
              src="img/landingrowbg.png"
              alt="Oshunwide illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
